<template>
	<div v-if="this.pageLoaded" class="filter-projects-container--outer">
		<div class="filter-projects-container">
			<div class="filter-projects-inner-container">
				<div class="filter-toggle-container">
					<h1>Projects</h1>
					<div v-on:click="ToggleFilters()" class="filter-toggle-arrow no-filters">{{ filterTitle }}</div>
				</div>
				<div id="filter-projects" class="filter-projects-item filters filter-projects-hidden">
					<div class="service-container">
						<div class="small-text">Service</div>
						<div class="service-filter-container filter-inner-container">
							<!-- change to only clear service filters? -->
							<p class="filter-btn filter-btn-service filter-btn-all small-text filter-button-active" v-on:click="ClearFilteredItems($event, `service`)">All Services</p>
							<div v-for="(item, index) in serviceFilter" :key="`serviceFilter_${index}`">
								<div
									:id="item"
									:class="`filter-btn ${item} filter-btn-service`"
									v-on:click="
										FilterProjects({ filterValue: item, filterType: 'service' });
										ToggleActiveClass(item, index, 0);
									"
								>
									<p class="small-text">{{ item }}</p>
								</div>
							</div>
						</div>
					</div>

					<div class="sector-container">
						<div class="small-text">Sector</div>
						<div class="sector-filter-container filter-inner-container">
							<p class="filter-btn filter-btn-sector filter-btn-all small-text filter-button-active" v-on:click="ClearFilteredItems($event, `sector`)">All Sectors</p>
							<div v-for="(item, index) in sectorFilter" :key="`sectorFilter_${index}`">
								<div
									:id="item"
									:class="`filter-btn ${item} filter-btn-sector`"
									v-on:click="
										FilterProjects({ filterValue: item, filterType: 'sector' });
										ToggleActiveClass(item, index, 1);
									"
								>
									<p class="small-text">{{ item }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="filter-projects-inner-container show-all">
				<div v-if="currentShowingCount <= 1">
					<p class="show-all-filters">Showing {{ currentShowingCount }} project</p>
				</div>
				<div v-else>
					<p class="show-all-filters">Showing {{ currentShowingCount }} projects</p>
				</div>

				<div class="reset-filters">
					<div class="filter-reset hide" v-on:click="ClearAllFilters($event)">Reset filters</div>
			</div>
			</div>
		</div>

		<div class="filter-projects-grid-container">
			<a v-for="(project, index) in paginatedItems" :key="`recipe_${index}`" class="filter-projects-grid-inner-container" :href="project.url">
				<div class="project-image-overlay">
					<img class="project-image" :loading="project.loading" :src="GetImageCropper(project.imageUrl)" />
				</div>
				<div class="project-text-container">
					<h2 :class="`project-title ${project.fontColour}`">
						{{ project.name }}
					</h2>
				</div>
			</a>
		</div>

		<div class="filter-projects-pagination-container" id="filter-projects-pagination-container" v-if="this.pageNumbers.length > 0 && filteredItems.length > 12">
			<div class="filter-projects-pagination-d">
				<img
					src="../../img/icons/arrow_left.svg"
					v-on:click="prevPage()
						HandleEllipse();
						HandleArrows();"
					class="filter-projects-pagination-arrow filter-projects-pagination-arrow-left"
					:class="{ 'filter-projects-pagination-arrow-disable': currentPage == 0 }"
				/>

				<div
					class="cp-projects-page-nav-page-number"
					:class="{ 'page-number-active': currentPage == item, 'page-number-ellipse': item == '...' }"
					v-for="(item, index) in this.pageNumbersClone"
					:key="`pageNumber_${index}`"
					v-on:click="
						GoToPageNo(item);
						HandleEllipse();
						HandleArrows();
					"
				>
					<p>{{ item == "..." ? item : item + 1 }}</p>
				</div>

				<img
					src="../../img/icons/arrow_right.svg"
					v-on:click="nextPage();
						HandleEllipse();
						HandleArrows();"
					class="filter-projects-pagination-arrow filter-projects-pagination-arrow-right"
					:class="{ 'filter-projects-pagination-arrow-disable': currentPage + 1 == pageCount }"
				/>
			</div>
			<div class="filter-projects-pagination-m">
				<img
					src="../../img/icons/arrow_left.svg"
					v-on:click="prevPage();
						HandleArrows();"
					class="filter-projects-pagination-arrow filter-projects-pagination-arrow-left"
					:class="{ 'filter-projects-pagination-arrow-disable': currentPage == 0 }"
				/>

				<p>Page {{ currentPage + 1 }} of {{ pageCountNum }}</p>

				<img
					src="../../img/icons/arrow_right.svg"
					v-on:click="nextPage();
						HandleArrows();"
					class="filter-projects-pagination-arrow filter-projects-pagination-arrow-right"
					:class="{ 'filter-projects-pagination-arrow-disable': currentPage + 1 == pageCount }"
				/>
			</div>
		</div>

		<div class="project-filter-spacer" v-if="filteredItems.length <= 12"></div>
	</div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
	components: {},
	data() {
		return {
			allProjects: [],
			sectorFilter: [],
			serviceFilter: [],
			filteredItems: [],
			paginate: ["sortedItems"],
			formattedDate: null,
			currentShowingCount: 0,
			allProjectsCount: 0,
			currentPage: 0,
			lastPage: 0,
			itemsPerPage: 12,
			filterPair: { sector: "", service: "" },
			pageCount: 0,
			pageNumbers: [],
			pageNumbersClone: [],
			pageLoaded: false,
			filterTitle: "",
			loading: "lazy",
		};
	},

	created() {
		this.filterPair.sector = "";
		this.filterPair.service = "";
		axios.get("/umbraco/api/ProjectApi/GetAllProjects").then((response) => {
			this.allProjects = response.data;
			this.filteredItems = response.data;

			//DO NOT PUSH LIVE, TEST PURPOSES ONLY
			// this.allProjects.push(...this.allProjects)
			// this.filteredItems.push(...this.filteredItems)
			///////////////////////
			this.allProjectsCount = this.allProjects.length;
			this.currentShowingCount = this.allProjects.length;
			this.pageCount = Math.ceil(this.filteredItems.length / this.itemsPerPage);
			if (this.allProjects != []) {
				this.pageLoaded = true;
			}

			this.pageNumbers = Array.from(Array(this.pageCount).keys());
			this.pageNumbersClone = [...this.pageNumbers];
			this.pageNumbersClone = this.pageNumbersClone.map(function(num) {
				return ++num;
			});
			this.HandleEllipse();
			this.HandleArrows();
		});

		axios.get("/umbraco/api/ProjectApi/GetProjectFilter").then((response) => {
			this.sectorFilter = response.data.sector;
			this.serviceFilter = response.data.service;
		});

		if (window.innerWidth < 770) {
			this.filterTitle = "Filter";
		} else {
			this.filterTitle = "Filter Projects";
		}
	},

	updated() {
		//opening wont work without a set height
		const filterContainer = document.getElementById("filter-projects");
		if (filterContainer) {
			this.GetRenderedHeight(filterContainer);
		}
	},

	computed: {
		paginatedItems() {
			const startIndex = this.currentPage * this.itemsPerPage;
			const endIndex = startIndex + this.itemsPerPage;

			var chosenOnes = this.filteredItems.slice(0, 4);
			chosenOnes.forEach((one) => {
				one.loading = "eager";
			});

			return this.filteredItems.slice(startIndex, endIndex);
		},

		pageCountNum() {
			return Math.ceil(this.filteredItems.length / this.itemsPerPage);
		},

		filterContainers() {
			const filterContainer = document.getElementById("filter-projects");
			return filterContainer.getElementsByClassName("filter-inner-container");
		},

		filterButtons() {
			const buttonContainerList = [];
			for (var i = 0; i < this.filterContainers.length; i++) {
				var buttonList = [];
				buttonList.push(this.filterContainers[i].getElementsByClassName("filter-btn"));
				buttonContainerList.push(buttonList);
			}
			return buttonContainerList;
		},
	},

	methods: {
		FilterProjects(filter) {
			filter.filterType === "sector" ? (this.filterPair.sector = filter.filterValue) : (this.filterPair.service = filter.filterValue);
			//show reset filters
			var resetFilters = document.getElementsByClassName("filter-reset")[0];
			resetFilters != null ? resetFilters.classList.remove("hide") : "";

			
			this.filterPair.service !== "" && this.filterPair.sector === ""
				? (this.filteredItems = this.allProjects.filter((item) => {
						return item.service.includes(this.filterPair.service);
				  }))
				: null;

			this.filterPair.service === "" && this.filterPair.sector !== ""
				? (this.filteredItems = this.allProjects.filter((item) => {
						return item.sector.includes(this.filterPair.sector);
				  }))
				: null;


			if (this.filterPair.service !== "" && this.filterPair.sector !== "") {
				this.filteredItems = this.allProjects.filter((item) => {
					return item.service.includes(this.filterPair.service);
				});

				this.filteredItems = this.filteredItems.filter((item) => {	
					return item.sector.includes(this.filterPair.sector);
				});
			}

			this.updatePageNumbers()
			this.currentShowingCount = this.filteredItems.length;
			this.ReturnToFirst();
		},

		ToggleActiveClass(item, index, filterNo) {
			if (filterNo == 0) {
				for (var j = 0; j < this.filterButtons[0][0].length; j++) {
					this.filterButtons[0][0][j].classList.remove("filter-button-active");
				}
				this.filterButtons[0][0][index + 1].classList.add("filter-button-active");
			}

			if (filterNo == 1) {
				for (var k = 0; k < this.filterButtons[1][0].length; k++) {
					this.filterButtons[1][0][k].classList.remove("filter-button-active");
				}
				this.filterButtons[1][0][index + 1].classList.add("filter-button-active");
			}
		},

		ClearFilteredItems(event, type) {
			var resetFilters = document.getElementsByClassName("filter-reset")[0];
			resetFilters != null ? resetFilters.classList.remove("hide") : "";


			if(type == "sector"){
				this.filterPair.sector = "";
			} else if (type == "service"){
				this.filterPair.service = "";
			}

			this.filterPair.service !== "" && this.filterPair.sector === ""
				? (this.filteredItems = this.allProjects.filter((item) => {
						return item.service.includes(this.filterPair.service);
				  }))
				: null

				this.filterPair.service === "" && this.filterPair.sector !== ""
				? (this.filteredItems = this.allProjects.filter((item) => {
						return item.sector.includes(this.filterPair.sector);
				  }))
				: null

			if (this.filterPair.service !== "" && this.filterPair.sector !== "") {
				this.filteredItems = this.allProjects.filter((item) => {
					return item.service.includes(this.filterPair.service);
				});

				this.filteredItems = this.filteredItems.filter((item) => {
					return item.sector.includes(this.filterPair.sector);
				});
			}

			if(this.filterPair.service == "" & this.filterPair.sector == ""){
				resetFilters.classList.add("hide");
				this.filteredItems = this.allProjects;
			}

			this.updatePageNumbers();
			this.currentShowingCount = this.filteredItems.length;
			this.ReturnToFirst();

			for (var i = 0; i < this.filterButtons.length; i++) {
				for (var j = 0; j < this.filterButtons[i][0].length; j++) {
					if (!this.filterButtons[i][0][j].classList.contains("filter-btn-all") && this.filterButtons[i][0][j].classList.contains(`filter-btn-${type}`)) {
						this.filterButtons[i][0][j].classList.remove("filter-button-active");
					}
				}
			}
			event.target.classList.add("filter-button-active");
		},

		ClearAllFilters(event) {
			this.filteredItems = this.allProjects;	
			this.filterPair.sector = "";
			this.filterPair.service = "";

			var resetFilters = document.getElementsByClassName("filter-reset")[0];
			resetFilters.classList.add("hide");
			

			for (var i = 0; i < this.filterButtons.length; i++) {
				for (var j = 0; j < this.filterButtons[i][0].length; j++) {
					if (!this.filterButtons[i][0][j].classList.contains("filter-btn-all") && this.filterButtons[i][0][j].classList.contains(`filter-btn-sector`)) {
						this.filterButtons[i][0][j].classList.remove("filter-button-active");
					}

					if (!this.filterButtons[i][0][j].classList.contains("filter-btn-all") && this.filterButtons[i][0][j].classList.contains(`filter-btn-service`)) {
						this.filterButtons[i][0][j].classList.remove("filter-button-active");
					}

					if(this.filterButtons[i][0][j].classList.contains("filter-btn-all")) {
						this.filterButtons[i][0][j].classList.add("filter-button-active");
					}
				}
			}
			event.target.classList.add("filter-button-active");

			this.updatePageNumbers();
			this.currentShowingCount = this.filteredItems.length;
			this.ReturnToFirst();
		},

		ToggleFilters() {
			const filterContainer = document.getElementById("filter-projects");

			this.GetRenderedHeight(filterContainer);

			const arrowToggle = document.querySelector(".filter-toggle-arrow");
			arrowToggle.classList.toggle("no-filters");
			filterContainer.classList.toggle("filter-projects-hidden");
		},

		GetRenderedHeight(element) {
			const computedElementStyle = window.getComputedStyle(element);
			const computedHeight = computedElementStyle.getPropertyValue("height");

			if (computedHeight != "0px") {
				element.style.height = computedHeight;
			}
		},

		FormatDate(date) {
			return moment(String(date)).format("DD MMM yyyy");
		},

		GetImageCropper(image) {
			if (image != null) {
				var value = 0;
				var newWidth = null;
				var newHeight = null;
				var cropLeft = 0.5;
				var cropTop = 0.5;

				if (window.screen.width <= 320) {
					value = window.screen.width / 320;
					newWidth = (value * 280).toFixed(0);
					newHeight = (value * 157.48).toFixed(0);
					return image + `?center=${cropTop},${cropLeft}&mode=crop&width=${newWidth}&height=${newHeight}&format=webp&quality=80&rnd=132945132427470000`;
				} else if (window.screen.width <= 767) {
					value = window.screen.width / 767;
					newWidth = (value * 777).toFixed(0);
					newHeight = (value * 437).toFixed(0);
					return image + `?center=${cropTop},${cropLeft}&mode=crop&width=${newWidth}&height=${newHeight}&format=webp&quality=80&rnd=132945132427470000`;
				} else {
					value = window.screen.width / 1920;
					newWidth = (value * 777).toFixed(0);
					newHeight = (value * 437).toFixed(0);
					return image + `?center=${cropTop},${cropLeft}&mode=crop&width=${newWidth}&height=${newHeight}&format=webp&quality=80&rnd=132945132427470000`;
				}
			}
		},

		prevPage() {
			if (this.currentPage > 0) {
				this.currentPage--;
			}
		},

		nextPage() {
			if (this.currentPage < this.pageCount - 1) {
				this.currentPage++;
			}
		},

		ReturnToFirst(){
			this.lastPage = this.currentPage;
			this.currentPage = 0;
		},

		GoToPageNo(n) {
			if (n != "...") {
				this.lastPage = this.currentPage;
				this.currentPage = n;
				window.scrollTo(0, 0);
			}
		},

		DisableElipses() {
			var pageNumberNodes = document.getElementsByClassName("cp-projects-page-nav-page-number");
			if (pageNumberNodes.length > 0);
			for (var i = 0; i < pageNumberNodes.length; i++) {
				pageNumberNodes[i].classList.remove("page-number-ellipse");

				if (pageNumberNodes[i].innerText == "...") {
					pageNumberNodes[i].classList.add("page-number-ellipse");
				}
			}
		},

		HandleEllipse() {
			this.pageNumbersClone = Array.from(Array(this.pageCount).keys());
			this.pageNumbersClone.map((x) => x + 1);

			if (this.pageCount > 6) {
				//FIRST 3 PAGES
				if (this.currentPage + 1 < this.pageCount - 4 && this.currentPage + 1 < 4) {
					this.pageNumbersClone.splice(4, this.pageCount - 5, "...").join();
				}
				//LAST 3 PAGES
				if (this.currentPage + 1 >= this.pageCount - 4 && this.currentPage + 1 > this.pageCount - 3) {
					this.pageNumbersClone.splice(1, this.pageCount - 5, "...").join();
				}
				//INBETWEEN PAGES
				if (!(this.currentPage + 1 < 4 || this.currentPage + 1 > this.pageCount - 3)) {
					this.pageNumbersClone.splice(1, this.pageCount - 5, "...").join();
					this.pageNumbersClone.splice(2, 1, this.currentPage - 1).join();
					this.pageNumbersClone.splice(3, 1, this.currentPage).join();
					this.pageNumbersClone.splice(4, 1, this.currentPage + 1).join();
					this.pageNumbersClone.splice(5, 0, "...").join();
				}
			}
		},

		HandleArrows() {
			window.scrollTo(0, 0);
		},

		updatePageNumbers() {
			this.pageCount = Math.ceil(this.filteredItems.length / this.itemsPerPage);
			this.pageNumbers = Array.from(Array(this.pageCount).keys());
			this.pageNumbersClone = [...this.pageNumbers];
			this.pageNumbersClone = this.pageNumbersClone.map(function(num) {
				return ++num;
			});

			this.HandleEllipse();


			return this.pageNumbersClone;
		},
	},
};
</script>

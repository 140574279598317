export default () => {
    const footerLogoContainer = document.getElementById("footer-logo");
    if (footerLogoContainer == null)
        return;
    import(/*webpackChunkName: 'footerlogo' */ "../classes/footerLogoClass")
        .then(({ default: footerLogo }) => {
        new footerLogo(footerLogoContainer);
    })
        .catch(() => "An Error has occured with Footer logo Module");
};

export default () => {
    const animContainer = document.querySelector('[approach-page]');
    if (animContainer == null)
        return;
    import(/* webpackChunkName: "approach animation container" */ "../classes/approachAnimationContainerClass")
        .then(({ default: animationContainer }) => {
        if (animContainer != null) {
            new animationContainer(animContainer);
        }
    });
};

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.pageLoaded
    ? _c("div", { staticClass: "filter-projects-container--outer" }, [
        _c("div", { staticClass: "filter-projects-container" }, [
          _c("div", { staticClass: "filter-projects-inner-container" }, [
            _c("div", { staticClass: "filter-toggle-container" }, [
              _c("h1", [_vm._v("Projects")]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "filter-toggle-arrow no-filters",
                  on: {
                    click: function($event) {
                      return _vm.ToggleFilters()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.filterTitle))]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "filter-projects-item filters filter-projects-hidden",
                attrs: { id: "filter-projects" }
              },
              [
                _c("div", { staticClass: "service-container" }, [
                  _c("div", { staticClass: "small-text" }, [_vm._v("Service")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "service-filter-container filter-inner-container"
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "filter-btn filter-btn-service filter-btn-all small-text filter-button-active",
                          on: {
                            click: function($event) {
                              return _vm.ClearFilteredItems($event, "service")
                            }
                          }
                        },
                        [_vm._v("All Services")]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.serviceFilter, function(item, index) {
                        return _c("div", { key: "serviceFilter_" + index }, [
                          _c(
                            "div",
                            {
                              class:
                                "filter-btn " + item + " filter-btn-service",
                              attrs: { id: item },
                              on: {
                                click: function($event) {
                                  _vm.FilterProjects({
                                    filterValue: item,
                                    filterType: "service"
                                  })
                                  _vm.ToggleActiveClass(item, index, 0)
                                }
                              }
                            },
                            [
                              _c("p", { staticClass: "small-text" }, [
                                _vm._v(_vm._s(item))
                              ])
                            ]
                          )
                        ])
                      })
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sector-container" }, [
                  _c("div", { staticClass: "small-text" }, [_vm._v("Sector")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "sector-filter-container filter-inner-container"
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "filter-btn filter-btn-sector filter-btn-all small-text filter-button-active",
                          on: {
                            click: function($event) {
                              return _vm.ClearFilteredItems($event, "sector")
                            }
                          }
                        },
                        [_vm._v("All Sectors")]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.sectorFilter, function(item, index) {
                        return _c("div", { key: "sectorFilter_" + index }, [
                          _c(
                            "div",
                            {
                              class:
                                "filter-btn " + item + " filter-btn-sector",
                              attrs: { id: item },
                              on: {
                                click: function($event) {
                                  _vm.FilterProjects({
                                    filterValue: item,
                                    filterType: "sector"
                                  })
                                  _vm.ToggleActiveClass(item, index, 1)
                                }
                              }
                            },
                            [
                              _c("p", { staticClass: "small-text" }, [
                                _vm._v(_vm._s(item))
                              ])
                            ]
                          )
                        ])
                      })
                    ],
                    2
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-projects-inner-container show-all" },
            [
              _vm.currentShowingCount <= 1
                ? _c("div", [
                    _c("p", { staticClass: "show-all-filters" }, [
                      _vm._v(
                        "Showing " +
                          _vm._s(_vm.currentShowingCount) +
                          " project"
                      )
                    ])
                  ])
                : _c("div", [
                    _c("p", { staticClass: "show-all-filters" }, [
                      _vm._v(
                        "Showing " +
                          _vm._s(_vm.currentShowingCount) +
                          " projects"
                      )
                    ])
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "reset-filters" }, [
                _c(
                  "div",
                  {
                    staticClass: "filter-reset hide",
                    on: {
                      click: function($event) {
                        return _vm.ClearAllFilters($event)
                      }
                    }
                  },
                  [_vm._v("Reset filters")]
                )
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "filter-projects-grid-container" },
          _vm._l(_vm.paginatedItems, function(project, index) {
            return _c(
              "a",
              {
                key: "recipe_" + index,
                staticClass: "filter-projects-grid-inner-container",
                attrs: { href: project.url }
              },
              [
                _c("div", { staticClass: "project-image-overlay" }, [
                  _c("img", {
                    staticClass: "project-image",
                    attrs: {
                      loading: project.loading,
                      src: _vm.GetImageCropper(project.imageUrl)
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "project-text-container" }, [
                  _c("h2", { class: "project-title " + project.fontColour }, [
                    _vm._v("\n\t\t\t\t\t" + _vm._s(project.name) + "\n\t\t\t\t")
                  ])
                ])
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        this.pageNumbers.length > 0 && _vm.filteredItems.length > 12
          ? _c(
              "div",
              {
                staticClass: "filter-projects-pagination-container",
                attrs: { id: "filter-projects-pagination-container" }
              },
              [
                _c(
                  "div",
                  { staticClass: "filter-projects-pagination-d" },
                  [
                    _c("img", {
                      staticClass:
                        "filter-projects-pagination-arrow filter-projects-pagination-arrow-left",
                      class: {
                        "filter-projects-pagination-arrow-disable":
                          _vm.currentPage == 0
                      },
                      attrs: { src: require("../../img/icons/arrow_left.svg") },
                      on: {
                        click: function($event) {
                          _vm.prevPage()
                          _vm.HandleEllipse()
                          _vm.HandleArrows()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm._l(this.pageNumbersClone, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: "pageNumber_" + index,
                          staticClass: "cp-projects-page-nav-page-number",
                          class: {
                            "page-number-active": _vm.currentPage == item,
                            "page-number-ellipse": item == "..."
                          },
                          on: {
                            click: function($event) {
                              _vm.GoToPageNo(item)
                              _vm.HandleEllipse()
                              _vm.HandleArrows()
                            }
                          }
                        },
                        [
                          _c("p", [
                            _vm._v(_vm._s(item == "..." ? item : item + 1))
                          ])
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("img", {
                      staticClass:
                        "filter-projects-pagination-arrow filter-projects-pagination-arrow-right",
                      class: {
                        "filter-projects-pagination-arrow-disable":
                          _vm.currentPage + 1 == _vm.pageCount
                      },
                      attrs: {
                        src: require("../../img/icons/arrow_right.svg")
                      },
                      on: {
                        click: function($event) {
                          _vm.nextPage()
                          _vm.HandleEllipse()
                          _vm.HandleArrows()
                        }
                      }
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "filter-projects-pagination-m" }, [
                  _c("img", {
                    staticClass:
                      "filter-projects-pagination-arrow filter-projects-pagination-arrow-left",
                    class: {
                      "filter-projects-pagination-arrow-disable":
                        _vm.currentPage == 0
                    },
                    attrs: { src: require("../../img/icons/arrow_left.svg") },
                    on: {
                      click: function($event) {
                        _vm.prevPage()
                        _vm.HandleArrows()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Page " +
                        _vm._s(_vm.currentPage + 1) +
                        " of " +
                        _vm._s(_vm.pageCountNum)
                    )
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass:
                      "filter-projects-pagination-arrow filter-projects-pagination-arrow-right",
                    class: {
                      "filter-projects-pagination-arrow-disable":
                        _vm.currentPage + 1 == _vm.pageCount
                    },
                    attrs: { src: require("../../img/icons/arrow_right.svg") },
                    on: {
                      click: function($event) {
                        _vm.nextPage()
                        _vm.HandleArrows()
                      }
                    }
                  })
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.filteredItems.length <= 12
          ? _c("div", { staticClass: "project-filter-spacer" })
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
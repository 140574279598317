export default () => {
    const menuItems = document.querySelectorAll('.cp-header');
    if (!menuItems)
        return;
    import(/* webpackChunkName: "menu" */ "./../classes/menuClass")
        .then(({ default: Menu }) => {
        new Menu();
    })
        .catch(() => "An error occurred");
};

import Vue from "vue";
import VuePaginate from "vue-paginate";
import projectsFilter from "./../apps/projectsFilter.vue";

export default () => {
	Array.prototype.unique = function () {
		return this.filter(function (value, index, self) {
			return self.indexOf(value) == index;
		});
	};

	if (!Array.prototype.last) {
		Array.prototype.last = function () {
			return this[this.length - 1];
		};
	}
	
	let newApp = document.querySelector("*[filter-projects]");

	if (newApp == null) return;
	new Vue({
		el: newApp,
		render: (h) => h(projectsFilter),
	});
};

export default () => {
    const homeItems = document.querySelectorAll('.cp-home-gallery-component');
    if (!homeItems)
        return;
    import(/* webpackChunkName: "homeGallery" */ "./../classes/homeGalleryClass")
        .then(({ default: HomeGallery }) => {
        homeItems.forEach(contentContainer => {
            new HomeGallery(contentContainer);
        });
    })
        .catch(() => "An error occurred");
};

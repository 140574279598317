export default () => {
    const numberCounterContainers = document.querySelectorAll('*[number-counter]');
    if (numberCounterContainers.length === 0)
        return;
    import(/* webpackChunkName: "numberCounter" */ "../classes/numberCounterClass")
        .then(({ default: NumberCounter }) => {
        numberCounterContainers.forEach(numberCounterContainers => {
            new NumberCounter(numberCounterContainers);
        });
    })
        .catch(() => "An error with Number Counter class occurred");
};

export default () => {
    const errorPageContainer = document.getElementById("error-page-animation");
    if (errorPageContainer == null)
        return;
    import(/*webpackChunkName: 'error page animation'*/ "../classes/errorPageAnimationClass")
        .then(({ default: errorAnimation }) => {
        new errorAnimation(errorPageContainer);
    })
        .catch(() => "An Error has occurred with 404 animation");
};

import AOS from 'aos';
import GalleryModule from './modules/galleryModule';
import MenuModule from './modules/menuModule';
import HeaderScroll from './modules/headerScrollModule';
import JobsComponentModule from './modules/jobsComponentModule';
import RelatedProjectsModule from './modules/relatedProjectsModule';
import SpotlightHover from './modules/spotLightHover';
import NumberCounter from './modules/numberCounterModule';
import TextBillboard from './modules/textBillboardModule';
import HomeGallery from './modules/homeGalleryModule';
import MapAnimation from './modules/mapAnimationModule';
import VideoModule from './modules/videoModule';
import GoBack from './util/goBack';
import ProjectFilter from './modules/projectsFilterModule';
import FooterLogo from './modules/footerLogoModule';
import ErrorPageAnimation from './modules/errorPageAnimationModule';
import ApproachPageAnimation from './modules/approachPageAnimationModule';
import approachAnimationContainerModule from './modules/approachAnimationContainerModule';
HomeGallery();
MenuModule();
GalleryModule();
HeaderScroll();
FooterLogo();
ErrorPageAnimation();
JobsComponentModule();
RelatedProjectsModule();
SpotlightHover();
NumberCounter();
TextBillboard();
MapAnimation();
VideoModule();
GoBack();
ProjectFilter();
ApproachPageAnimation();
approachAnimationContainerModule();
if (document.getElementsByClassName("cp-project-hero-copy").length) {
    console.log("r");
    document.getElementsByClassName("cp-project-hero-copy")[0].innerHTML = document.getElementsByClassName("cp-project-hero-copy")[0].innerHTML.replace("’", "'");
}
if (document.getElementsByClassName("cp-job-listing").length) {
    console.log("r");
    document.getElementsByClassName("cp-job-listing")[0].innerHTML = document.getElementsByClassName("cp-job-listing")[0].innerHTML.replace("’", "'");
}
AOS.init({
    once: true,
    mirror: false,
});

export default () => {
    const mapContainer = document.getElementById('map-container');
    if (!mapContainer)
        return;
    import(/*webpackChunkName: 'mapAnimation' */ "../classes/mapAnimationClass")
        .then(({ default: mapAnimation }) => {
        new mapAnimation();
    })
        .catch(() => "An Error Occurred with Map Animation Module");
};

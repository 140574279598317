export default () => {
    const backgroundContainer = document.querySelectorAll('.cp-related-projects-container');
    if (backgroundContainer.length === 0)
        return;
    import(/* webpackChunkName: "relatedProjects" */ "../classes/relatedProjectsClass")
        .then(({ default: RelatedProjects }) => {
        backgroundContainer.forEach(background => {
            new RelatedProjects(background);
        });
    })
        .catch(() => "An arror with Related Projects Component occurred");
};

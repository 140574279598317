<template>
    <div class="cp-jobs-component">
        <div class="cp-jobs-component-container" job-item-element>
            <div v-for="(item, index) in allItems" :key="`job_${index}`" class="cp-jobs-component-item hover-effect" job-item v-on:click="GoToJob(item.jobUrl)">
                <button class="cp-jobs-component-name">{{item.name}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import delay from "../util/delay";

export default {
    components:{

    },
    data(){
        return{
            allItems: [],
            paginate: ["sortedItems"],
            currentItems: 2,
            allHTMLJobs: []
        }
    },

    created() {
        axios.get(`/umbraco/api/JobPageApi/getall`).then((response) => {
            this.allItems = response.data;
        })
    },

    mounted(){
    delay(500).then(() => {
        this.allHTMLJobs = document.querySelectorAll("*[job-item]");
        this.AddHoverEvent(this.allHTMLJobs);

    })
    },


    methods: {
        GoToJob(jobUrl){
            window.location.href=jobUrl;
        },
        AddHoverEvent(jobs){
            // console.log(jobs)
            jobs.forEach((item) => {
			item.addEventListener("mouseover", (e) => {
				this.Spotlight(item);
			});
		});
        },
        Spotlight(job){
            job.addEventListener("mousemove", (e) => {
			const { x, y } = job.getBoundingClientRect();
			job.style.setProperty("--x", e.clientX - x - 15);
			job.style.setProperty("--y", e.clientY - y - 5);
		});
        }
    }

}
</script>

export default () => {
    const header = document.querySelectorAll('.cp-header');
    if (!header)
        return;
    import(/*webpackChunkName: 'headerScroll' */ "../classes/headerScrollClass")
        .then(({ default: HeaderScroll }) => {
        new HeaderScroll();
    })
        .catch(() => "An Error Occurred");
};

export default () => {
    const galleryContainers = document.querySelectorAll('*[gallery-component]');
    if (galleryContainers.length === 0)
        return;
    import(/* webpackChunkName: "gallery" */ "./../classes/galleryClass")
        .then(({ default: Gallery }) => {
        galleryContainers.forEach(galleryContainer => {
            new Gallery(galleryContainer);
        });
    })
        .catch(() => "An error with gallery component occurred");
};

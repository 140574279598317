export default () => {

  if(document.getElementById("cta-button")){
    let backButton = document.getElementById("cta-button");

    backButton.addEventListener("click", (e) => {
      if(document.referrer == location.origin){
        history.back();
      }
      else{
        window.location.href = "/";
      }
  });
  }
  

  
};

export default () => {
    const billboardItems = document.querySelectorAll('.cp-text-billboard');
    if (!billboardItems)
        return;
    import(/* webpackChunkName: "textBillboardModule" */ "./../classes/textBillboardClass")
        .then(({ default: TextBillboard }) => {
        new TextBillboard();
    })
        .catch(() => "An error with Text Billboard Module occurred");
};

import Vue from 'vue';
import VuePaginate from 'vue-paginate';
import jobsComponent from './../apps/jobsComponent.vue';
Vue.use(VuePaginate);

export default () => {
    Array.prototype.unique = function () {
        return this.filter(function (value, index, self) {
            return self.indexOf(value) === index;
        })
    };


    if (!Array.prototype.last) {
        Array.prototype.last = function () {
            return this[this.length - 1];
        }
    };

    let newApp = document.getElementById("jobs-component");
    if(!newApp) return
    new Vue({
        el: newApp,
        render: h=>h(jobsComponent),
    })
}
export default () => {
    const jobItems = document.querySelectorAll("*[job-item-element]");
    if (jobItems.length === 0)
        return;
    import(/* webpackChunkName: "spotlightHover" */ "./../classes/spotlightHover")
        .then(({ default: SpotlightHover }) => {
        jobItems.forEach(jobs => {
            new SpotlightHover(jobs);
        });
    })
        .catch(() => "An error with Spotlight Hover has occurred");
};

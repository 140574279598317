var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cp-jobs-component" }, [
    _c(
      "div",
      {
        staticClass: "cp-jobs-component-container",
        attrs: { "job-item-element": "" }
      },
      _vm._l(_vm.allItems, function(item, index) {
        return _c(
          "div",
          {
            key: "job_" + index,
            staticClass: "cp-jobs-component-item hover-effect",
            attrs: { "job-item": "" },
            on: {
              click: function($event) {
                return _vm.GoToJob(item.jobUrl)
              }
            }
          },
          [
            _c("button", { staticClass: "cp-jobs-component-name" }, [
              _vm._v(_vm._s(item.name))
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
export default () => {
    const videoContainers = document.querySelectorAll('*[video-player]');
    if (videoContainers.length === 0)
        return;
    import(/* webpackChunkName: "videoPlayer" */ "./../classes/VideoClass")
        .then(({ default: VideoPlayer }) => {
        videoContainers.forEach(videoContainer => {
            new VideoPlayer(videoContainer);
        });
    })
        .catch(() => "An error with Accordion component occurred");
};
